import * as React from 'react';
import useRouter from 'hooks/useRouter';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import useLoadedMessage from 'hooks/useLoadedMessage';
import InstaPostsMain from 'pages/InstaPostsMain';
import GoogleReviewsMain, { GoogleReviewsMode } from 'pages/GoogleReviewsMain';
import useGoogleReviewsData from 'hooks/useGoogleReviews';
import useInstagramPostsData from 'hooks/useInstagramPosts';
import styles from './Pages.module.scss';

const Pages: React.FC = () => {
    const [reviewIndex, setReviewIndex] = React.useState(0);
    const [postIndex, setPostIndex] = React.useState(0);
    const { renderButton, next } = useRouter();
    const { path } = useRouteMatch();
    const location = useLocation();
    const { reviews } = useGoogleReviewsData();
    const { posts } = useInstagramPostsData();
    useLoadedMessage();

    React.useEffect(() => {
        if (reviews) {
            setReviewIndex(0);
        }
    }, [reviews, setReviewIndex]);

    React.useEffect(() => {
        if (location.pathname === '/screens/insta-posts') {
            setReviewIndex((index) => index + 1);
        }
    }, [location.pathname]);

    React.useEffect(() => {
        if (location.pathname === '/screens/') {
            setPostIndex((index) => index + 1);
        }
    }, [location.pathname]);

    const proceed = React.useCallback(
        (event: React.SyntheticEvent<HTMLButtonElement>) => {
            event.preventDefault();
            next();
        },
        [next],
    );

    return (
        <>
            <Switch location={location}>
                <Route path={`${path}insta-posts`}>
                    <InstaPostsMain
                        className={styles.route}
                        post={posts?.[postIndex % (posts?.length || 1)]}
                    />
                </Route>

                <Route exact path={path}>
                    <GoogleReviewsMain
                        mode={
                            reviewIndex % 2 === 0
                                ? GoogleReviewsMode.light
                                : GoogleReviewsMode.dark
                        }
                        className={styles.route}
                        review={reviews?.[reviewIndex % (reviews?.length || 1)]}
                    />
                </Route>
            </Switch>
            {renderButton && (
                <IconButton
                    onClick={proceed}
                    className={styles.button}
                    size="large"
                >
                    <SkipNextIcon />
                </IconButton>
            )}
        </>
    );
};

export default Pages;
