import localforage from 'localforage';

const storage = localforage.createInstance({
    name: 'hqMural',
});

const CSRF_TOKEN_KEY = 'csrf_token';
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

export async function setCsrfToken(csrfToken: string): Promise<void> {
    await storage.setItem(CSRF_TOKEN_KEY, csrfToken);
}

export function getCsrfToken(): Promise<string | null> {
    return storage.getItem(CSRF_TOKEN_KEY);
}

export async function setAccessToken(accessToken: string): Promise<void> {
    await storage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export function getAccessToken(): Promise<string | null> {
    return storage.getItem(ACCESS_TOKEN_KEY);
}

export async function setRefreshToken(refreshToken: string): Promise<void> {
    await storage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function getRefreshToken(): Promise<string | null> {
    return storage.getItem(REFRESH_TOKEN_KEY);
}
