import RoutesProvider from 'components/RoutesProvider/RoutesProvider';
import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from 'lib/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import initialize from 'lib/i18n';
import { LocalizeProvider } from 'react-localize-redux';
import moment from 'moment-timezone';
import theme from './theme';
import Pages from '../Pages';
import ToggleFullscreen from '../ToggleFullscreen';

import Init from '../Init';

moment.locale('en');

const RELOAD_MINUTES = process.env.REACT_APP_RELOAD_MINUTES
    ? parseInt(process.env.REACT_APP_RELOAD_MINUTES, 10)
    : 0.1;

export default function App(): React.ReactElement {
    React.useEffect(() => {
        const timer = setTimeout(() => {
            window.location.reload();
        }, RELOAD_MINUTES * 60000);

        return () => {
            clearTimeout(timer);
        };
    }, []);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizeProvider initialize={initialize}>
                    <ApolloProvider client={client}>
                        <Router>
                            <Switch>
                                <Route exact path="/">
                                    <Init />
                                </Route>
                                <Route path="/screens/">
                                    <RoutesProvider>
                                        <Pages />
                                    </RoutesProvider>
                                </Route>
                            </Switch>
                            <ToggleFullscreen />
                        </Router>
                    </ApolloProvider>
                </LocalizeProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
