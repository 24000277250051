import { gql } from '@apollo/client';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import { JwtPayload } from 'lib/getValidToken';
// eslint-disable-next-line import/no-cycle
import client from './client';
import { setAccessToken } from './storage';
import { GRAPHQL_CLIENT_NAMES } from './constants';

const mutation = gql`
    mutation extendToken($refreshToken: String!) {
        extendToken(application: hqMural, refreshToken: $refreshToken) {
            accessToken
            expiresIn
        }
    }
`;

type ExtendTokenResult = {
    extendToken: {
        accessToken: string;
        expiresIn: number;
    };
};

type ExtendTokenVariables = {
    refreshToken: string;
    __noAuth: true;
};

type Token = {
    accessToken: string;
};

export default async function extendToken(
    token: string,
): Promise<Token | null> {
    const { data } = await client.mutate<
        ExtendTokenResult,
        ExtendTokenVariables
    >({
        mutation,
        variables: { refreshToken: token, __noAuth: true },
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.auth,
        },
    });

    const accessToken = data?.extendToken;

    if (accessToken) {
        await setAccessToken(accessToken.accessToken);

        jwtDecode<JwtPayload>(token);

        return { accessToken: accessToken.accessToken };
    }

    return null;
}
