import * as React from 'react';
import { instagramPosts } from 'api/queries';
import useReputationManagementQuery from 'api/useReputationManagementQuery';
import { pollInterval, minInstagramLikes, instagramLimit } from 'lib/util';
import { InstagramPost } from '@bondvet/types/instagram';

type InstagramPostsQuery = {
    latestMostLikedInstagramPosts: readonly InstagramPost[];
};

type InstagramPostsVariables = {
    limit: number;
    minLikes: number;
};

interface InstagramPostsData {
    loading: boolean;
    posts: readonly InstagramPost[];
}

export const useInstagramPosts = (): InstagramPostsData => {
    const { data, loading } = useReputationManagementQuery<
        InstagramPostsQuery,
        InstagramPostsVariables
    >(instagramPosts, {
        variables: {
            limit: instagramLimit,
            minLikes: minInstagramLikes,
        },
        pollInterval,
        fetchPolicy: 'network-only',
    });

    const posts = React.useMemo(
        () =>
            (data?.latestMostLikedInstagramPosts || [])
                .slice()
                .sort(() => Math.random() - 0.5),
        [data?.latestMostLikedInstagramPosts],
    );

    return {
        loading,
        posts,
    };
};

export default useInstagramPosts;
