import * as React from 'react';
import styles from 'pages/GoogleReviewsMain/GoogleReviewsMain.module.scss';
import classnames from 'classnames';
import ShoutOutCoinBlue from 'assets/shout-out-coin-blue.svg';
import LightHeader from 'assets/google-reviews-light-header.svg';
import DarkHeader from 'assets/google-reviews-dark-header.svg';
import GoogleReviewsFooter from 'assets/google-reviews-footer.svg';
import DogCoinLight from 'assets/dog-coin-light.png';
import DogCoinDark from 'assets/dog-coin-dark.png';
import Stars from 'components/Stars';
import LogoWhiteRed from 'assets/logo-white-red.png';
import LogoBlueRed from 'assets/logo-blue-red.png';
import { GoogleReview } from './review';

interface GoogleReviewsMainProps {
    className: string | null;
    mode: GoogleReviewsMode;
    review?: null | GoogleReview;
}

export enum GoogleReviewsMode {
    dark,
    light,
}

const GoogleReviewsMain: React.FC<GoogleReviewsMainProps> = ({
    className,
    mode,
    review,
}: GoogleReviewsMainProps) => {
    if (!review) return null;

    return (
        <div className={classnames(className, styles.root)}>
            {mode === GoogleReviewsMode.light && (
                <div className={styles.background} />
            )}

            <img
                src={mode === GoogleReviewsMode.dark ? DarkHeader : LightHeader}
                className={styles.header}
                alt="Header Background"
            />
            <img
                src={
                    mode === GoogleReviewsMode.dark ? LogoWhiteRed : LogoBlueRed
                }
                className={styles.logo}
                alt="Bondvet Logo"
            />
            <img
                src={ShoutOutCoinBlue}
                className={styles.coin}
                alt="Shout-out Coin"
            />
            <img
                src={GoogleReviewsFooter}
                className={styles.footer}
                alt="Footer Background"
            />

            <div className={styles.commentContainer}>
                <div className={styles.comment}>
                    <div
                        className={classnames(styles.triangle, styles.shadow, {
                            [styles.dark]: mode === GoogleReviewsMode.dark,
                        })}
                    />
                    <div
                        className={classnames(styles.triangle, {
                            [styles.dark]: mode === GoogleReviewsMode.dark,
                        })}
                    />
                    <div
                        className={classnames(styles.mainShadow, {
                            [styles.dark]: mode === GoogleReviewsMode.dark,
                        })}
                    />
                    <div
                        className={classnames(styles.main, {
                            [styles.dark]: mode === GoogleReviewsMode.dark,
                        })}
                    >
                        <img
                            src={
                                mode === GoogleReviewsMode.dark
                                    ? DogCoinDark
                                    : DogCoinLight
                            }
                            className={styles.commentCoin}
                            alt="Dog Coin"
                        />
                        <Stars
                            score={review.rating}
                            classes={{ root: styles.stars }}
                        />
                        <div className={styles.text}>{review.comment}</div>
                        <div className={styles.author}>
                            <strong>- {review.name},</strong> {review?.location}
                        </div>
                    </div>
                </div>
            </div>

            <svg
                style={{
                    visibility: 'hidden',
                    position: 'absolute',
                }}
                width="0"
                height="0"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
            >
                <defs>
                    <filter id="goo">
                        <feGaussianBlur
                            in="SourceGraphic"
                            stdDeviation="16"
                            result="blur"
                        />
                        <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                            result="goo"
                        />
                        <feComposite
                            in="SourceGraphic"
                            in2="goo"
                            operator="atop"
                        />
                    </filter>
                </defs>
            </svg>
        </div>
    );
};

export default GoogleReviewsMain;
