import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultTimeoutSeconds, renderButton } from 'lib/util';
import RoutesContext from './context';

const TIMER_ROUTES: TimerRoutes = ['screens/', 'screens/insta-posts'];

export type TimerRoutes = {
    0: string;
    1: string;
} & string[];

export default function RoutesProvider({
    children,
}: React.PropsWithChildren<unknown>) {
    const { pathname, search } = useLocation();

    const routes = React.useMemo(() => {
        const routeNames = [...TIMER_ROUTES] as TimerRoutes;

        return routeNames.map((route) => `/${route}`) as TimerRoutes;
    }, []);

    const [current, setCurrent] = React.useState<number>(() => {
        const initialIndex = routes.findIndex((route) => route === pathname);

        return Math.max(0, initialIndex);
    });
    const history = useHistory();

    // get the start time, so we can calculate the timeout for switching
    // to the next screen
    const [startTime, setStartTime] = React.useState(() => Date.now());

    const [timeoutSeconds, setTimeoutSeconds] = React.useState(
        defaultTimeoutSeconds,
    );

    const next = React.useCallback(() => {
        const nextIndex = (current + 1) % routes.length;
        setCurrent(nextIndex);
        setStartTime(Date.now());
        history.push(routes[nextIndex] + search);

        // switch back to default timeout seconds
        setTimeoutSeconds(defaultTimeoutSeconds);
    }, [current, history, routes, search]);

    // make sure that we don't show routes that we don't want to show
    // this could happen since the settings flag defaults to `true`
    // and we could be on a (full) page reload on /impact-scores
    React.useEffect(() => {
        if (!routes.find((route) => route === pathname)) {
            console.info('fix route', routes[current], pathname);
            history.push(routes[current] + search);
        }
    }, [routes, pathname, search, history, current]);

    React.useEffect(() => {
        if (
            !renderButton &&
            new URLSearchParams(search).get('fixed-path') !== 'true'
        ) {
            const timeoutTime = Math.max(
                0,
                timeoutSeconds * 1000 - (Date.now() - startTime),
            );

            const timer = setTimeout(() => {
                next();
            }, timeoutTime);

            return () => {
                clearTimeout(timer);
            };
        }

        return () => {};
    }, [next, startTime, timeoutSeconds, search]);

    const value = React.useMemo(
        () => ({ renderButton, next, setTimeoutSeconds }),
        [next, setTimeoutSeconds],
    );

    return (
        <RoutesContext.Provider value={value}>
            {children}
        </RoutesContext.Provider>
    );
}
