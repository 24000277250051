import * as React from 'react';
import { googleReviews } from 'api/queries';
import useReputationManagementQuery from 'api/useReputationManagementQuery';
import {
    maxCommentLength,
    minCommentLength,
    minRating,
    pollInterval,
    reviewLimit,
} from 'lib/util';
import { GoogleReview } from 'pages/GoogleReviewsMain/review';

type RawGoogleReview = {
    _id: string;
    reviewer?: {
        displayName?: string;
    };
    date: string;
    rating: number;
    comment: string;
    location?: { name: string };
};

type GoogleReviewsQuery = {
    latestGoogleReviews: RawGoogleReview[];
};

type GoogleReviewsVariables = {
    limit: number;
    minRating: number;
    minCommentLength?: number;
    maxCommentLength?: number;
};

interface GoogleReviewsData {
    loading: boolean;
    reviews: GoogleReview[];
}

export const useGoogleReviews = (): GoogleReviewsData => {
    const { data, loading } = useReputationManagementQuery<
        GoogleReviewsQuery,
        GoogleReviewsVariables
    >(googleReviews, {
        variables: {
            limit: reviewLimit,
            minRating,
            minCommentLength,
            maxCommentLength,
        },
        pollInterval,
        fetchPolicy: 'network-only',
    });
    const reviews = React.useMemo(() => {
        return (
            data?.latestGoogleReviews?.map(
                ({ _id, reviewer, rating, comment, location }) => ({
                    _id,
                    name: reviewer?.displayName,
                    rating,
                    comment,
                    location: location?.name,
                }),
            ) || []
        );
    }, [data?.latestGoogleReviews]);

    return {
        loading,
        reviews,
    };
};

export default useGoogleReviews;
