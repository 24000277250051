// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createQueryString(query: Record<string, any>): string {
    return Object.keys(query)
        .reduce(
            (result: string[], key: string) => [
                ...result,
                `${key}=${encodeURIComponent(query[key])}`,
            ],
            [''],
        )
        .join('&');
}

export const manualMode =
    !!process.env.REACT_APP_MANUAL_NEXT &&
    /^true$/i.test(process.env.REACT_APP_MANUAL_NEXT);

export const renderButton = manualMode && typeof document !== 'undefined';

/*
export function spacing(theme: Theme, value = 1): string {
    const result =
        typeof theme.spacing === 'function'
            ? theme.spacing(value)
            : (theme.spacing as any).unit * value;

    return theme.typography.pxToRem(result);
}
 */

export const minCommentLength = parseInt(
    process.env.REACT_APP_MIN_REVIEW_COMMENT_LENGTH || '100',
    10,
);

export const maxCommentLength = parseInt(
    process.env.REACT_APP_MAX_REVIEW_COMMENT_LENGTH || '400',
    10,
);

export const minRating = parseInt(process.env.REACT_APP_MIN_RATING || '0', 10);

export const reviewLimit = parseInt(
    process.env.REACT_APP_REVIEW_LIMIT || '0',
    10,
);

export const instagramLimit = parseInt(
    process.env.REACT_APP_INSTAGRAM_LIMIT || '0',
    10,
);

export const pollInterval =
    parseInt(process.env.REACT_APP_POLL_INTERVAL_IN_SECONDS || '0', 10) * 1000;

export const defaultTimeoutSeconds = process.env
    .REACT_APP_DEFAULT_SCREEN_SWITCHING_SECONDS
    ? parseInt(process.env.REACT_APP_DEFAULT_SCREEN_SWITCHING_SECONDS, 10)
    : 10;

export const minInstagramLikes = parseInt(
    process.env.REACT_APP_MIN_INSTAGRAM_LIKES || '0',
    10,
);
