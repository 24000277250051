import * as React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import extendToken from 'lib/extendToken';
import { setRefreshToken } from 'lib/storage';

const Init: React.FC = () => {
    const location = useLocation();
    const [finishedLoading, setFinishedLoading] = React.useState<
        boolean | null
    >(null);
    const redirect = React.useMemo<string | null>(() => {
        if (finishedLoading) {
            const params = new URLSearchParams(location.search);

            params.delete('token');
            const path = params.get('path');

            let targetPath = `/screens/`;

            if (path) {
                params.delete('path');
                params.set('fixed-path', 'true');
                targetPath = `${targetPath}/${
                    path.startsWith('/') ? path.substring(1) : path
                }`;
            }

            const queryString = params.toString();

            if (queryString) {
                return `${targetPath}?${queryString}`;
            }

            return targetPath;
        }
        return null;
    }, [location.search, finishedLoading]);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const refreshToken = params.get('token');

        if (refreshToken) {
            extendToken(refreshToken as string).then(() => {
                setFinishedLoading(true);
                return setRefreshToken(refreshToken as string);
            });
        } else {
            setFinishedLoading(true);
        }
    }, [location]);

    if (redirect) {
        return <Redirect to={redirect} />;
    }
    return <p>Loading</p>;
};

export default Init;
