import { gql } from '@apollo/client';

export const googleReviews = gql`
    query latestGoogleReviews(
        $limit: Int!
        $minRating: Int!
        $minCommentLength: Int
        $maxCommentLength: Int
    ) {
        latestGoogleReviews(
            limit: $limit
            minRating: $minRating
            minCommentLength: $minCommentLength
            maxCommentLength: $maxCommentLength
        ) {
            _id
            reviewer {
                displayName
            }
            date
            comment
            rating
            location {
                name
            }
        }
    }
`;

export const instagramPosts = gql`
    query latestMostLikedInstagramPosts($limit: Int!, $minLikes: Int!) {
        latestMostLikedInstagramPosts(limit: $limit, minLikes: $minLikes) {
            _id
            caption
            like_count
            media_url
            media_type
            children {
                data {
                    id
                    media_url
                }
            }
        }
    }
`;
