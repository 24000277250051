import {
    QueryResult,
    QueryHookOptions,
    useQuery,
    TypedDocumentNode,
    OperationVariables,
} from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';

export default function useReputationManagementQuery<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
    return useQuery<TData, TVariables>(query, {
        ...options,
        context: {
            ...options?.context,
            clientName: GRAPHQL_CLIENT_NAMES.reputationManagement,
        },
    });
}
