import * as React from 'react';
import { useTranslate } from 'hooks/useTranslate';
import classnames from 'classnames';
import { InstagramPost } from '@bondvet/types/instagram';
import { Carousel } from 'react-responsive-carousel';
import { defaultTimeoutSeconds } from 'lib/util';
import moment from 'moment-timezone';
import styles from './InstaPostsMain.module.scss';
import StatusBar from './assets/status-bar-iphone-x.svg';
import IgNavBar from './assets/ig-nav-bar.svg';
import IgProfileBanner from './assets/ig-profile-banner.png';
import IgReactions from './assets/ig-reactions.png';
import IgBottomBar from './assets/ig-bottom-bar.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface InstaPostsMainProps {
    className: string | null;
    post?: InstagramPost;
}

const InstaPostsMain: React.FC<InstaPostsMainProps> = ({
    className,
    post,
}: InstaPostsMainProps) => {
    const translate = useTranslate();
    const mediaUrlArray = React.useMemo(() => {
        if (!post) return [];
        if (post.children?.data) return post.children?.data;
        return [
            {
                id: post._id,
                media_url: post.media_url,
            },
        ];
    }, [post]);
    const interval =
        (defaultTimeoutSeconds * 1000) / (mediaUrlArray?.length || 1);

    return (
        <div className={classnames(className, styles.root)}>
            <img src={StatusBar} alt="Phone Status Bar" />
            <img src={IgNavBar} alt="Instagram Navigation Bar" />
            <img src={IgProfileBanner} alt="Instagram Profile Banner" />
            <div className={styles.clock}>
                {moment().tz('America/New_York').format('h:mma')}
            </div>
            <div className={styles.content}>
                <Carousel
                    autoPlay
                    interval={interval}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    dynamicHeight={false}
                    centerMode={false}
                >
                    {mediaUrlArray?.map((url) => (
                        <div key={url?.id} className={styles.imgWrapper}>
                            <img
                                key={url?.id}
                                src={url?.media_url}
                                alt="Instagram Post"
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
            <img src={IgReactions} alt="Instagram Post Reactions" />
            <div className={styles.subText}>
                <strong>
                    {post?.like_count} {translate('mural.instaPosts.likes')}
                </strong>
                <span>
                    <strong>{translate('mural.instaPosts.instaName')}</strong>
                    {post?.caption}
                </span>
            </div>
            <img src={IgBottomBar} alt="Instagram Navigation Bar" />
        </div>
    );
};

export default InstaPostsMain;
