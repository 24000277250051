import * as React from 'react';
import { renderButton } from 'lib/util';

export interface RoutesContextType {
    renderButton: boolean;
    next: () => void;
    setTimeoutSeconds: (timeoutSeconds: number) => void;
}

const RoutesContext = React.createContext<RoutesContextType>({
    renderButton,
    next: () => {},
    setTimeoutSeconds: () => {},
});

export default RoutesContext;
